import React from 'react';

import { ClassNameProps } from '@ComponentProps';

interface LabelProps extends React.PropsWithChildren<ClassNameProps> {
  label: string;
  name: string;
}

export const Label: React.FC<LabelProps> = ({ name, className, children, label }) => (
  <label
    aria-label={label}
    className={className}
    htmlFor={name}
  >
    {children}
  </label>
);
